import { Spin } from "antd";
import moment from 'moment';
import QueryString from 'query-string';
import React, { Component } from "react";
import { connect } from "react-redux";
import { withToastManager } from 'react-toast-notifications';
import { dateTimeFormatFilterUpdate, dateTimeFormatView } from '../../../const/Formats';
import { fetchCustomerCategories, fetchCustomers } from "../../../redux/actions/customers";
import { fetchSalesHeaders } from "../../../redux/actions/salesInvoiceHeaders";
import { fetchSalesLines } from '../../../redux/actions/salesInvoiceLines';
import { addSalesLine } from '../../../redux/actions/salesLines';
import { fetchActiveCall } from '../../../redux/actions/calls';
import { getAuthData, getFlatPositions, getLoggedInUser, getSalesInvoiceHeaderMetadata, getSalesInvoiceHeadersBySearchKey, getSalesInvoiceHeaderTotal, getTopPosition, isFetchingSalesInvoiceHeaders } from "../../../redux/reducers";
import { getSearchKey, encodeUrlSearchParams } from '../../../utils/Search';
import PromatePageHeader from '../../common/PromatePageHeader';
import InvoicesTable from './InvoicesTable';

class SalesInvoicesScreen extends Component {
    baseUrl = window.location.pathname;
    state = {
        orderId: undefined,
        customerId: undefined,
        loading: true,
        invoiceAdding: false,
        orders: [],
        recordTotal: 0,
        filter: {
            UserCode: undefined,
            RouteCode: undefined,
            OutletCategoryCode: undefined,
            text: undefined,
        },
        currentPage: 1,
        selectedRow: undefined
    }

    async componentDidMount() {
        const { authData: { userId }, fetchCustomerCategories, fetchCustomers } = this.props;

        await fetchCustomerCategories();
        await fetchCustomers({ UserCode: userId });

        this.setState({
            filter: {
                ...this.state.filter,
                UserCode: userId,
            }
        }, () => this.updateUrl(this.props));

        this.checkValidRoute();

        await this.fetchSalesInvoices();
    }

    updateUrl = (props) => {
        const { history, topPosition, authData: { userId }, match: { params: { orderId, customerNo } }, location: { state } } = props;

        this.setState({ orderId: decodeURIComponent(orderId), customerId: customerNo });

        const urlSearchParams = QueryString.parse(window.location.search);

        this.setState({ currentPage: urlSearchParams.page ? Number(urlSearchParams.page) : 1 }, () => {
            delete urlSearchParams.UserHierarchy;
            delete urlSearchParams.page;

            if (state && state.from && state.from === "salesReturn") {
                urlSearchParams.redirect = 'false';
            }

            if (topPosition) {
                const searchParams = encodeUrlSearchParams({
                    UserHierarchy: [{ value: topPosition.positionCode }],
                    from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
                    to: moment(new Date()).endOf("day"),
                    UserCode: userId,
                    ...urlSearchParams,
                }, this.state.currentPage);
                history.replace(`${this.baseUrl}?${searchParams}`);
            }
        })
    }

    checkValidRoute = () => {
        const { match: { params: { orderId } } } = this.props;

        const urlSearchParams = QueryString.parse(window.location.search);

        if (urlSearchParams.redirect !== 'false') {
            this.props.history.replace({ pathname: `/sales/ReturnOrder/${encodeURIComponent(orderId)}` })
        }
    }

    fetchSalesInvoices = async () => {
        let urlSearchParams = QueryString.parse(window.location.search);
        delete urlSearchParams.page;

        this.setState({
            filter: {
                ...this.state.filter,
                ...urlSearchParams,
                UserHierarchy: [{ value: urlSearchParams.UserHierarchy }],
                UserTag: [urlSearchParams.UserHierarchy],
                from: moment(urlSearchParams.from, dateTimeFormatView),
                to: moment(urlSearchParams.to, dateTimeFormatView)
            }
        }, async () => {
            this.setState({ loading: true })
            let filter = this.state.filter;

            if (filter.text) {
                filter.No = `*${filter.text}*`
                delete filter.text;
            }

            const response = await this.props.fetchSalesHeaders({
                ...filter,
                UserHierarchy: [{ value: urlSearchParams.UserHierarchy }],
                UserTagFilterOne: urlSearchParams.UserHierarchy,
                OrderDateFilter: moment(urlSearchParams.from, dateTimeFormatView).format(dateTimeFormatFilterUpdate) + '..' + moment(urlSearchParams.to, dateTimeFormatView).format(dateTimeFormatFilterUpdate),
                SellToCusNo: this.state.customerId,
            }, this.state.currentPage);

            if (response) {
                filter.text = String(filter.No).split('*')[1];
                delete filter.No;

                this.setState({ loading: false, orders: response.data, recordTotal: response.recordTotal, filter })
            }
        })
    }

    setDefaultCustomerOption = customer => {
        this.setState({ filter: { ...this.state.filter, CustNo: customer.No, } })
    }

    setDefaultRouteOption = route => {
        this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
    }

    handleSearchClick = async () => {
        let { currentPage, filter } = this.state;
        delete filter.page
        const searchParams = encodeUrlSearchParams({
            ...filter,
        }, currentPage);

        this.props.history.replace(`${this.baseUrl}?${searchParams}`);

        await this.fetchSalesInvoices();
    }

    handleInputDateChange = (field, value) => {
        this.setState({ filter: { ...this.state.filter, [field]: value } })
    };

    handleInputTextChange = (filter, value) => {
        let newFilter = { ...this.state.filter, [filter]: value };
        this.setState({ filter: newFilter });
    }

    onPaginationChange = (data) => {
        this.setState({ currentPage: data }, async () => {
            let { currentPage, filter } = this.state;
            const searchParams = encodeUrlSearchParams({
                ...filter,
            }, currentPage);

            this.props.history.replace(`${this.baseUrl}?${searchParams}`);

            await this.fetchSalesInvoices();
        });
    }

    handleRowSelect = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRow: selectedRows })
    }

    handleAddingInvoice = async () => {
        const { auth } = JSON.parse(localStorage.getItem('state'));

        if (!this.state.selectedRow) {
            this.props.toastManager.add('Select sales invoice first', { autoDismiss: true, appearance: 'error' });
        } else {

            const activeCall = await this.props.fetchActiveCall(this.state.filter.UserCode);

            if (auth.authData.roleCode === "CSR") {
                if (activeCall.data[0].CustNo !== this.state.customerId) {
                    this.props.toastManager.add('There is an active call for another customer. please close the call and try.', { autoDismiss: true, appearance: 'error' });
                    return;
                }
    
                if (activeCall.data[0].StartReasonCode !== "CS-0005") {
                    this.props.toastManager.add('There is an active call for another Reason. please close the call and try.', { autoDismiss: true, appearance: 'error' });
                    return;
                }
            }

            this.setState({ invoiceAdding: true });

            const salesLines = await this.props.fetchSalesLines({ DocNo: this.state.selectedRow[0].No, SellToCusNo: this.state.customerId });

            let responseDetails = [];

            for (let line of salesLines.data) {
                const response = await this.props.addSalesLine({ ...line, UserCode: this.state.filter.UserCode, DocType: '5', DocNo: this.state.orderId });
                if (response.error) {
                    this.props.toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
                    responseDetails.push(0);
                    break;
                } else {
                    responseDetails.push(1)
                }
            }
            this.setState({ invoiceAdding: false });
            if (!responseDetails.includes(0)) {
                this.props.toastManager.add('Invoice items are adding successfully', { autoDismiss: true, appearance: 'success' });

                const searchParams = encodeUrlSearchParams({
                    ...this.state.filter,
                    redirect: true,
                }, Number(this.state.currentPage));

                this.props.history.replace(`${this.baseUrl}?${searchParams}`);

                this.props.history.push(`/sales/ReturnOrder/${encodeURIComponent(this.state.orderId)}`);
            }
        }
    }

    render() {
        const { orders, recordTotal, loading, currentPage, filter, invoiceAdding } = this.state;

        return (
            <PromatePageHeader
                title={'Sales Invoices'}
                // style={styles.pageHeader}
                onBack={() => this.props.history.replace({ pathname: `/sales/ReturnOrder/${encodeURIComponent(this.state.orderId)}` })
                } >
                <Spin spinning={loading} tip={"Loading Sales Invoices"}>
                    <InvoicesTable
                        orders={orders}
                        total={recordTotal}
                        onPaginationChange={this.onPaginationChange}
                        currentPage={currentPage}
                        filter={filter}
                        setDefaultCustomerOption={this.setDefaultCustomerOption}
                        handleSearchClick={this.handleSearchClick}
                        handleInputDateChange={this.handleInputDateChange}
                        handleInputTextChange={this.handleInputTextChange}
                        handleRowSelect={this.handleRowSelect}
                        handleAddingInvoice={this.handleAddingInvoice}
                        invoiceAdding={invoiceAdding}
                    />
                </Spin>
            </PromatePageHeader >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const searchKey = getSearchKey();
    return {
        orders: getSalesInvoiceHeadersBySearchKey(state, searchKey),
        authData: getAuthData(state),
        loggedInUser: getLoggedInUser(state),
        topPosition: getTopPosition(state),
        flatPositions: getFlatPositions(state),
        total: getSalesInvoiceHeaderTotal(state, searchKey),
        totalAmount: getSalesInvoiceHeaderMetadata(state, searchKey).TotalAmountIncluVAT,
        metaData: getSalesInvoiceHeaderMetadata(state, searchKey),
        loading: isFetchingSalesInvoiceHeaders(state)
    };
};

export default withToastManager(connect(mapStateToProps, { fetchSalesHeaders, fetchCustomers, fetchCustomerCategories, fetchSalesLines, addSalesLine, fetchActiveCall })(SalesInvoicesScreen));