const crypto = require('crypto');
export const encryptValue = (text, key) => {
    const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), Buffer.from('16charlongvector'));
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return encrypted.toString('hex');
}

export const decryptValue = (encryptedText) => {
    const encryptionKey = Buffer.from('userType'.padEnd(32, '0')); 
    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(encryptionKey), Buffer.from('16charlongvector'));
    let decrypted = decipher.update(Buffer.from(encryptedText, 'hex'));
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }