import React, { Component } from 'react';
import { Alert, Form, Icon,Select , Button, Input as InputPassword } from 'antd';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { authenticate } from '../redux/actions/auth';
import { ProMateGreen } from '../const/Theme';
import packageJson from '../../package.json';
import Input from '../components/common/EnglishInput';
import { encryptValue } from "../components/common/encryption";
const { Option } = Select;
const crypto = require('crypto');
class Login extends Component {
  state = {
    submitting: false,
    showPasswordValidationError: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, toastManager, authenticate } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        authenticate(values).then(response => {
          if (response.error)
            toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
          this.setState({ submitting: false });
        });
      }
    });
  };

  validatePassword = (rule, value, callback) => {
    const { form } = this.props;
    if (!this.isValidPassword(value)) {
      this.setState({ showPasswordValidationError: true });
      const errorMessage = '';
      callback(errorMessage);
      form.setFieldValue('confirm', '');
    } else {
      this.setState({ showPasswordValidationError: false });
      callback();
    }
  };

  isValidPassword = (inputtxt) => {
    var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;
    if (inputtxt.match(regex)) {
      return true;
    } else {
      return false;
    }
  }
  selectType = (value) => {    
    const encryptionKey = Buffer.from('userType'.padEnd(32, '0')); 
    const encryptedMessage = encryptValue(value, encryptionKey);
    localStorage.setItem('userType', encryptedMessage);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitting, showPasswordValidationError } = this.state;

    return (
      <div style={styles.loginPage}>
        <Form onSubmit={this.handleSubmit} style={styles.loginForm}>
          <Form.Item>
            <img alt='ProMate Logo' src='/logo.png' style={styles.logo} />
            <div style={styles.loginFormVersion}>v{packageJson.version}</div>
            {process.env.REACT_APP_BUILD_ID && <div style={styles.loginFormBuild}>Build: {process.env.REACT_APP_BUILD_ID}</div>}
          </Form.Item>
          <Form.Item style={{ marginBottom: 15}}>
            {getFieldDecorator('dropdown', {
              rules: [
                { required: true, message: 'Please select an option!' }
              ]
            })(
              <Select 
                placeholder="Select User Range"
                onChange={this.selectType}
              >
                <Option value="r1">R1</Option>
                <Option value="r2">R2</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 15}}>
            {getFieldDecorator('username', {
              rules: [
                { required: true, message: 'Please input your username!' }
              ]
            })(
              <Input
                prefix={<Icon type="user" style={styles.textInput} />}
                placeholder="Username"
              />,
            )}
          </Form.Item>
          <Form.Item >
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your password!' },
                {
                  validator: this.validatePassword
                }
              ]
            })(
              <InputPassword.Password
                placeholder="Password"
                iconRender={visible => (visible ? <Icon.EyeTwoTone /> : <Icon.EyeInvisibleOutlined />)}
              />
            )}
          </Form.Item>
          {showPasswordValidationError &&
            <Alert
              style={{ width: '100%', marginBottom: 10 }}
              description={
                <div style={{ textAlign: 'left' }}>
                  <span>Your password must contain:</span>
                  <ul>
                    <li><span class='item-text'>At least 6 characters</span></li>
                    <li>
                      <span>Including following:</span>
                      <div>
                        <ul>
                          <li><span>Lower case letters (a-z)</span></li>
                          <li><span>Upper case letters (A-Z)</span></li>
                          <li><span>Numbers (0-9)</span></li>
                          <li><span>Special characters (ex. !@#)</span></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              }
              type='error'
            />}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={styles.loginFormButton} loading={submitting}>
              Log in
            </Button>
            <Button type="link" style={styles.loginFormForgot} onClick={() => this.props.history.push('/requestPasswordResetOTP')}>
              Reset password
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withToastManager(connect(null, { authenticate })(Form.create({ name: 'login' })(Login)));

const styles = {
  loginPage: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginForm: {
    width: '300px',
  },
  loginFormReset: {
    float: 'right',
  },
  loginFormButton: {
    width: '100%',
    backgroundColor: ProMateGreen,
  },
  logo: {
    width: 300,
    padding: 20
  },
  textInput: {
    color: 'rgba(0,0,0,.25)'
  },
  loginFormForgot: {
    float: 'right',
  },
  loginFormVersion: {
    width: '300px',
    textAlign: 'center',
    color: '#777',
    marginTop: -20,
  },
  loginFormBuild: {
    width: '300px',
    textAlign: 'center',
    textSize: 6,
    color: '#777',
    marginTop: -20,
  },
};