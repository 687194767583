import React, { Component } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { Col, PageHeader, Row, Button, Modal } from 'antd';
import Radium from 'radium';
import { logOut } from '../redux/actions/auth';
import { getAuthData, getLoggedInUser, getFlatPositions, getTripSchedules, getNetworkModeDetector } from '../redux/reducers';
import { fetchTripSchedules } from '../redux/actions/tripSchedules';
import { fetchSalesUserHierarchy } from '../redux/actions/users';
import { ProMateGreen, ProMateGreenBorder } from '../const/Theme';
import { Link } from 'react-router-dom';
import { FaBoxes, FaClock, FaFileInvoice, FaInbox, FaUser, FaUsers, FaWarehouse, FaPlusCircle, FaThList, FaTruckMoving, FaUserTie, FaCalendar, FaSignOutAlt, FaCartPlus, FaShoppingCart, FaCoins, FaPhoneAlt, FaExternalLinkAlt, FaFileImport, FaRoute, FaReplyAll, FaSyncAlt, FaBalanceScaleLeft, FaReply, FaPaperPlane, FaLink, FaExclamationCircle, FaFile, FaFileExport, FaHourglassStart } from 'react-icons/fa'
import { GiShop } from 'react-icons/gi';
import { MdLayers, MdLayersClear, MdLocationCity } from 'react-icons/md';
import { dateTimeFormatFilterUpdate, dateFormatView } from '../const/Formats';
import ImportData from './Files/Json/ImportData';
import ExportData from './Files/Json/ExportData';
import { decryptValue } from "./common/encryption";

import { AiOutlineSchedule, AiOutlineStock } from 'react-icons/ai';


import moment, { min } from "moment";
import ReactLiveClock from 'react-live-clock';
import packageJson from '../../package.json';
import PromatePageHeader from './common/PromatePageHeader';
import {
  CUSTOMERS,
  DISTRIBUTORS,
  SALES_REPRESENTATIVES,
  SALES_ORDERS,
  SALES_INVOICES,
  RECEIPTS,
  DISTRIBUTOR_PAYMENTS,
  CREDIT_NOTES,
  PURCHASING_INVOICES,
  CALLS,
  SUPPLIER_RETURNS,
  EXPENSES,
  EXPENSE_CATEGORIES,
  GRNs,
  INVENTORY,
  PURCHASING_ORDERS,
  SUPPLIER_PAYMENTS,
  WAREHOUSES,
  ROUTES,
  STOCK_TRANSFERS,
  STOCK_TAKE,
  SALES_RETURNS,
  TRIP_SCHEDULER,
  INVOICE_SETTLEMENT,
  CUSTOMER_CREDIT_INVOICES,
  SALES_RETURN_INVOICES,
  PURCHASE_RETURNS,
  PURCHASE_CREDIT_INVOICES,
  FAILED_ORDERS,
  REPORTS,
  TARGET_SCHEDULER,
} from "../const/Permissions";
import { array } from 'prop-types';

const { confirm } = Modal;

class Home extends Component {

  state = {
    defaultRoute: undefined,
  }

  getRouteOptions = () => {
    const { authData: { userId, roleCode } } = this.props;
    return [

      // {
      //   title: 'ORDERS',
      //   to: { pathname:  '/orders', state: {} },
      //   color: '#00A2BD'
      // },

      {
        title: 'CUSTOMERS',
        to: { pathname: '/customers', state: {} },
        color: '#00DBF5',
        key: 'CUSTOMERS',
        permitedAction: CUSTOMERS.VIEW
      },
      {
        title: 'DISTRIBUTORS',
        to: { pathname: '/distributors', state: {} },
        color: '#00DBF5',
        permitedAction: DISTRIBUTORS.VIEW
      },
      // {
      //   title: 'SALES REPRESENTATIVES',
      //   to: { pathname: '/salesRepresentatives', state: {} },
      //   color: '#00DBF5',
      //   permitedAction: [ ...Object.values(SALES_REPRESENTATIVES) ]
      // },

      // {
      //   title: 'COLLECTIONS',
      //   to: { pathname: '/collections', state: {} },
      //   color: '#FFCB6E'
      // },

      {
        title: 'SALES ORDERS',
        to: { pathname: '/sales/Order', state: {} },
        color: '#FFCB6E',
        permitedAction: SALES_ORDERS.VIEW
      },
      {
        title: 'SALES INVOICES',
        to: { pathname: '/salesInvoices', state: {} },
        color: '#68EDCB',
        permitedAction: SALES_INVOICES.VIEW
      },

      {
        title: 'RECEIPTS',
        to: { pathname: '/receipts', state: {} },
        color: '#00DBF5',
        permitedAction: RECEIPTS.VIEW
      },

      {
        title: 'DISTRIBUTOR PAYMENTS',
        to: { pathname: '/distributorPayments', state: {} },
        color: '#00DBF5',
        permitedAction: DISTRIBUTOR_PAYMENTS.VIEW
      },

      {
        title: 'CUSTOMER CREDIT NOTES',
        to: { pathname: '/sales/CreditMemo', state: {} },
        color: '#00A2BD',
        permitedAction: CREDIT_NOTES.VIEW
      },

      {
        title: 'CUSTOMER CREDIT INVOICES',
        to: { pathname: '/postedCreditNotes/CreditMemo', state: {} },
        color: '#00A2BD',
        permitedAction: CUSTOMER_CREDIT_INVOICES.VIEW
      },

      // {
      //   title: 'PURCHASING INVOICES',
      //   to: { pathname: '/purchasingInvoices', state: {} },
      //   color: '#00DBF5',
      //   permitedAction: [ ...Object.values(PURCHASING_INVOICES) ]
      // },

      {
        title: 'CALLS',
        to: { pathname: '/calls', state: {} },
        color: '#00A2BD',
        permitedAction: CALLS.VIEW
      },

      // {
      //   title: 'SUPPLIER RETURNS',
      //   to: { pathname: '/supplierReturns', state: {} },
      //   color: '#00A2BD',
      //   permitedAction: [ ...Object.values(SUPPLIER_RETURNS) ]
      // },
      // {
      //   title: 'NEW SUPPLIER RETURN',
      //   to: { pathname: '/customers/' + userId + '/supplierReturns/new', state: {} },
      //   color: '#00DBF5'
      // },
      {
        title: 'EXPENSES',
        to: { pathname: '/expenses', state: {} },
        color: '#00A2BD',
        permitedAction: EXPENSES.VIEW
      },


      // {
      //   title: 'EXPENSE CATEGORIES',
      //   to: { pathname: '/expenseCategories', state: {} },
      //   color: '#00A2BD',
      //   permitedAction: [ ...Object.values(EXPENSE_CATEGORIES) ]
      // },

      // {
      //   title: 'GRNs',
      //   to: { pathname: '/grns', state: {} },
      //   color: '#00A2BD',
      //   permitedAction: [ ...Object.values(GRNs) ]
      // },

      // {
      //   title: 'NEW GRN',
      //   to: { pathname: '/grns', state: { from: 'grns' } },
      //   color: '#FFCB6E'
      // },

      {
        title: 'INVENTORY',
        to: { pathname: '/inventory', state: undefined },
        color: '#FFCB6E',
        permitedAction: INVENTORY.VIEW
      },
      // {
      //   title: 'INVOICES',
      //   to: { pathname:  '/invoices', state: {} },
      //   color: '#68EDCB'
      // },


      // {
      //   title: 'PURCHASING ORDER',
      //   to: { pathname: '/purchasingOrders', state: { from: 'purchasingOrders' } },
      //   color: '#00DBF5'
      // },

      // {
      //   title: 'NEW PURCHASING ORDER',
      //   to: { pathname: '/purchasingOrders/new', state: { from: 'purchasingOrders' } },
      //   color: '#00DBF5'
      // },

      // {
      //   title: 'SUPPLIER PAYMENTS',
      //   to: { pathname: '/payments', state: {} },
      //   color: '#FFCB6E',
      //   permitedAction: [ ...Object.values(SUPPLIER_PAYMENTS) ]
      // },

      // {
      //   title: 'NEW PAYMENT',
      //   to: { pathname: '/customers/' + userId + '/payments/new', state: {} },
      //   color: '#00DBF5'
      // },

      // {
      //   title: 'WAREHOUSES',
      //   to: { pathname: '/warehouses', state: {} },
      //   color: '#FFCB6E',
      //   permitedAction: [ ...Object.values(WAREHOUSES) ]
      // },

      {
        title: 'ROUTES',
        to: { pathname: '/routes', state: {} },
        color: '#FFCB6E',
        key: 'ROUTES',
        permitedAction: ROUTES.VIEW
      },

      {
        title: 'STOCK TRANSFERS',
        to: { pathname: '/stockTransfers', state: {} },
        color: '#FFCB6E',
        permitedAction: STOCK_TRANSFERS.VIEW
      },


      {
        title: 'SALES RETURNS',
        to: { pathname: '/sales/ReturnOrder', state: {} },
        color: '#00DBF5',
        permitedAction: SALES_RETURNS.VIEW
      },

      {
        title: 'SALES RETURN INVOICES',
        to: { pathname: '/salesReturnRecipts/ReturnOrder', state: {} },
        color: '#00DBF5',
        permitedAction: SALES_RETURN_INVOICES.VIEW
      },

      {
        title: 'TRIP SCHEDULER',
        to: { pathname: '/tripScheduler', state: {} },
        color: '#00DBF5',
        permitedAction: TRIP_SCHEDULER.VIEW
      },

      {
        title: 'SALES INVOICE SETTLEMENT',
        to: { pathname: '/invoicesettlement', state: {} },
        color: '#00DBF5',
        permitedAction: INVOICE_SETTLEMENT.VIEW
      },

      {
        title: 'PURCHASING ORDERS',
        to: { pathname: '/purchasing/Order', state: {} },
        color: '#00DBF5',
        permitedAction: PURCHASING_ORDERS.VIEW
      },

      {
        title: 'PURCHASE RETURNS',
        to: { pathname: '/purchasing/ReturnOrder', state: {} },
        color: '#00DBF5',
        permitedAction: PURCHASE_RETURNS.VIEW
      },

      {
        title: 'PURCHASING INVOICES',
        to: { pathname: '/purchasingInvoices', state: {} },
        color: '#00DBF5',
        permitedAction: PURCHASING_INVOICES.VIEW
      },

      {
        title: 'STOCK TAKE',
        to: { pathname: '/stockTake', state: {} },
        color: '#FFCB6E',
        permitedAction: STOCK_TAKE.VIEW
      },

      {
        title: 'PURCHASE CREDIT INVOICES',
        to: { pathname: '/purchasingCreditInvoices', state: {} },
        color: '#00DBF5',
        permitedAction: PURCHASE_CREDIT_INVOICES.VIEW
      },

      {
        title: 'FAILED ORDERS',
        to: { pathname: '/FailedSalesOrders', state: {} },
        color: '#FFCB6E',
        permitedAction: FAILED_ORDERS.VIEW
      },

      {
        title: 'REPORTS',
        to: { pathname: '/dailyReport', state: {} },
        color: '#FFCB6E',
        permitedAction: REPORTS.VIEW
      },
      {
        to: null,
        title: 'IMPORT',
        color: '#FFCB6E',
        permitedAction: REPORTS.VIEW
      },
      {
        to: null,
        title: 'EXPORT',
        color: '#FFCB6E',
        permitedAction: REPORTS.VIEW
      },
      {
        title: 'ROUTE WISE PLANNING & PERFORMANCE',
        to: { pathname: '/targetScheduler', state: {} },
        color: '#FFCB6E',
        // permitedAction: TARGET_SCHEDULER.VIEW
        permitedAction: REPORTS.VIEW
      },
    ];
  }
  // handleLogOut = () => {
  //   this.props.logOut();
  // }

  handleLogOut = (context, props) => {
    if (this.props.isSystemOffline) {
      confirm({
        title: "Log Out!",
        content: "Please switch to online mode before logging out. Or you will lose all offline data.",
        maskClosable: false,
        onOk() {
          context.showModal = false
        },
        onCancel() {
          context.showModal = false
        },
      });
    } else {
      this.props.logOut();
    }


  }
  getRange(){
    if (decryptValue(localStorage.getItem('userType')) == "r1") {
      return "Range - 1";
    }else if (decryptValue(localStorage.getItem('userType')) == "r2") {
      return "Range - 2";
    }
    return "";
  }

  handleLink = screenName => {
    switch (screenName) {
      case 'CUSTOMERS':
        return '/customers';
      case 'ORDERS':
        return '/orders';
      // case 'COLLECTIONS':
      //   return '/collections';
      case 'ITEMS':
        return '/inventory';
      case 'DISTRIBUTOR':
        return '/distributors';
      case 'PURCHASING_INVOICES':
        return '/purchasingInvoice';
      default:
        return '/';
    }
  }

  renderTileIcon = (screenName) => {
    switch (screenName) {
      case 'CUSTOMERS':
        return <FaUsers style={styles.tileIcon} />
      case 'DISTRIBUTORS':
        return <FaTruckMoving style={styles.tileIcon} />
      case 'SALES REPRESENTATIVES':
        return <FaUserTie style={styles.tileIcon} />
      case 'SALES ORDERS':
        return <FaCartPlus style={styles.tileIcon} />
      case 'SALES INVOICES':
        return <FaShoppingCart style={styles.tileIcon} />
      case 'RECEIPTS':
        return <FaCoins style={styles.tileIcon} />
      case 'DISTRIBUTOR PAYMENTS':
        return <FaCoins style={styles.tileIcon} />
      case 'CUSTOMER CREDIT NOTES':
        return <MdLayersClear style={styles.tileIcon} />
      case 'CUSTOMER CREDIT INVOICES':
        return <MdLayers style={styles.tileIcon} />
      case 'PURCHASING INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />
      case 'CALLS':
        return <FaPhoneAlt style={styles.tileIcon} />
      case 'EXPENSES':
        return <FaExternalLinkAlt style={styles.tileIcon} />
      case 'EXPENSE CATEGORIES':
        return <FaFileImport style={styles.tileIcon} />
      case 'GRNs':
        return <FaPlusCircle style={styles.tileIcon} />
      // case 'COLLECTIONS':
      //   return '/collections';
      case 'INVENTORY':
        return <FaThList style={styles.tileIcon} />
      case 'WAREHOUSES':
        return <MdLocationCity style={styles.tileIcon} />
      case 'STOCK TRANSFERS':
        return <FaSyncAlt style={styles.tileIcon} />
      case 'SALES RETURNS':
        return <FaReplyAll style={styles.tileIcon} />
      case 'SALES RETURN INVOICES':
        return <FaReply style={styles.tileIcon} />
      case 'TRIP SCHEDULER':
        return <FaPaperPlane style={styles.tileIcon} />
      case 'ROUTES':
        return <FaRoute style={styles.tileIcon} />
      case 'SALES INVOICE SETTLEMENT':
        return <FaLink style={styles.tileIcon} />
      case 'PURCHASING ORDERS':
        return <FaCartPlus style={styles.tileIcon} />
      case 'PURCHASE RETURNS':
        return <FaReplyAll style={styles.tileIcon} />
      case 'PURCHASING INVOICES':
        return <FaCartPlus style={styles.tileIcon} />
      case 'STOCK TAKE':
        return <FaBalanceScaleLeft style={styles.tileIcon} />
      case 'PURCHASE CREDIT INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />
      case 'FAILED ORDERS':
        return <FaExclamationCircle style={styles.tileIcon} />
      case 'REPORTS':
        return <FaFile style={styles.tileIcon} />
      case 'IMPORT':
        return <FaFileImport style={styles.tileIcon} />
      case 'EXPORT':
        return <FaFileExport style={styles.tileIcon} />
      case 'ROUTE WISE PLANNING & PERFORMANCE':
        return <FaHourglassStart style={styles.tileIcon} />
      default:
        return '/';
    }

  }

  renderLogout = () =>
    <span
      onClick={this.handleLogOut}
      key='logout'
      style={styles.logout}>
      <FaSignOutAlt style={{ fontSize: 25, color: '#ffffff', transform: 'rotate(180deg)' }} />
    </span>

  renderTile = ({ to, title: key }) => {
    if (to) {
      return (
        <Link to={to} key={key}>
          <Col xs={12} sm={8} style={{ height: '100%', display: 'flex', alignContents: 'center' }}>
            <div style={styles.topTiles}>
              {this.renderTileIcon(key)}
              <h2 style={{ color: '#FFFFFF', fontWeight: 600 }}>{key}</h2>
            </div>
          </Col>
        </Link>
      );
    } else if (key === 'IMPORT') {
      return this.props.isSystemOffline ? <></> : <ImportData title={key} icon={this.renderTileIcon(key)} />
    } else if (key === 'EXPORT') {
      return (<ExportData title={key} icon={this.renderTileIcon(key)} />);
    } else {
      return (
        <Col className="gutter-row" xs={12} sm={6}>
          <div style={styles.tile} key={key}>
            <div style={styles.tileText}>{key}</div>
          </div>
        </Col>
      );
    }
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 80;
    return window.innerHeight - (headerHeight);
  }
  componentDidMount() {
    const { authData: { displayName = "", userId }, loggedInUser: { permissions = [] }, flatPositions = {} } = this.props;
    this.props.fetchSalesUserHierarchy(this.props.authData);

    const UserHierarchyValues = flatPositions && Object.values(flatPositions).filter(position => position.user && (position.user.code === userId && position.user.role === 'CSR'))
    const filter = {
      UserCode: userId,
      StatusFilter: 'APPROVED',
      TripStatusFilter: 'Not Started',
      StartingDateTimeFilter: moment(new Date()).startOf("day").add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(new Date()).endOf("day").add('minutes', -330).format(dateTimeFormatFilterUpdate),
    }

    if (UserHierarchyValues.length > 0) {
      filter.UserTagFilterOne = UserHierarchyValues[0].positionCode;
      this.props.fetchTripSchedules(filter, 1, null).then(response => {
        if (response.recordTotal > 0) {

          var routeNames = [];
          (response.records).map(record => {
            routeNames.push(record.RouteName);
          }
          )
          this.setState({
            defaultRoute: routeNames
          })
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { authData: { displayName = "", userId } } = this.props;

    if (JSON.stringify(this.props.flatPositions) !== JSON.stringify(nextProps.flatPositions)) {
      const UserHierarchyValues = nextProps.flatPositions && Object.values(nextProps.flatPositions).filter(position => position.user && (position.user.code === userId && position.user.role === 'CSR'))
      const filter = {
        UserCode: userId,
        StatusFilter: 'APPROVED',
        TripStatusFilter: 'Not Started',
        StartingDateTimeFilter: moment(new Date()).startOf("day").add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(new Date()).endOf("day").add('minutes', -330).format(dateTimeFormatFilterUpdate),
      }

      if (UserHierarchyValues.length > 0) {

        filter.UserTagFilterOne = UserHierarchyValues[0].positionCode;
        this.props.fetchTripSchedules(filter, 1, null).then(response => {

          if (response.recordTotal > 0) {

            var routeNames = [];
            (response.records).map(record => {
              routeNames.push(record.RouteName);
            }
            )
            this.setState({
              defaultRoute: routeNames
            })
          }
        });
      }
    }
  }

  offlineButton(mode) {
    if (mode) {
      return "Offline";
    } else {
      return "Online"
    }
  }

  render() {
    const { defaultRoute } = this.state;
    const { authData: { displayName = "", userId }, loggedInUser: { permissions = [] }, isSystemOffline } = this.props;

    return (
      <div>
        <PromatePageHeader
          title={'Dashboard'}
          titleColor={'white'}
          extra={this.context.screenType === 'DESKTOP' && [this.renderLogout()]}
          showHomeIcon={false}
          style={styles.pageHeader}
          showToggleButton={!this.state.showToggleButton}
        >

          <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
            <Row style={{ padding: 5 }}>
              <Col sm={24} style={{ height: '100%', display: 'flex', alignContents: 'center' }}>
                <div style={{ border: '2px solid', borderColor: ProMateGreen, width: '100%', height: '100%', minHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1em', margin: 5, marginTop: 10, textAlign: 'left', color: ProMateGreen }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <FaUser style={{ fontSize: '1.5em' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                      <span style={{ fontSize: '1.5em', paddingLeft: '0.5em', textAlign: 'left' }}>{userId}</span>
                      {displayName && <span style={{ fontSize: '1.5em', paddingLeft: '0.5em', textAlign: 'left' }}>{displayName}</span>}
                      {<span style={{ fontSize: '1.5em', paddingLeft: '0.5em', textAlign: 'left' }}>{this.getRange()}</span>}
                    </div>
                  </div>


                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FaClock style={{ fontSize: '1.5em' }} />
                      <span style={{ fontSize: '1.5em', paddingLeft: '0.5em' }}><ReactLiveClock format={'HH:mm:ss'} ticking={true} /></span>
                    </div>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FaCalendar style={{ fontSize: '1.5em' }} />
                      <span style={{ fontSize: '1.5em', paddingLeft: '0.5em' }}>{moment().format(dateFormatView)}</span>
                    </div>
                  </div>

                  {defaultRoute && defaultRoute.map((value, index) => {
                    return <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: '1.5em', textAlign: 'left' }} key={index} >
                          <FaRoute style={{ fontSize: '1em' }} /> {value}
                        </div>
                      </div>
                    </div>
                  })}


                </div>
              </Col>
            </Row>
            <Row>
              <Row style={{ height: '30%', padding: 5, paddingBottom: 0 }}>
                {this.getRouteOptions().map(option => permissions.includes(option.permitedAction) && this.renderTile(option))}
              </Row>
            </Row>
            <Row>
              <Col sm={24} style={{ display: 'flex', justifyContent: 'center', color: '#777' }}>
                ProMate RD v{packageJson.version}
              </Col>
            </Row>
          </Row>
        </PromatePageHeader>
      </div>
    );
  }
}

Home.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    flatPositions: getFlatPositions(state),
    isSystemOffline: getNetworkModeDetector(state),
  };
};

export default connect(mapStateToProps, { logOut, fetchTripSchedules, fetchSalesUserHierarchy })(Radium(Home));

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    maxHeight: 80
  },
  logout: {
    cursor: 'pointer',
    marginLeft: 25
    //position: 'absolute',
    //top: 33,
    //right: 20
  },
  callButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: 15,
    right: 25,
    zIndex: 10000,
    color: '#ffffff',
    backgroundColor: ProMateGreen,
  },
  tile: {
    backgroundColor: ProMateGreen,
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    paddingTop: '100%',
    margin: 8,
    borderRadius: 8,
    ':hover': {
      backgroundColor: ProMateGreenBorder,
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileText: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center',
    fontSize: '20px',
    color: 'white',
    paddingTop: '45%'
  },
  tileIcon: {
    fontSize: 65,
    paddingBottom: 4
  },
  topTiles: {
    backgroundColor: ProMateGreen,
    width: '100%',
    height: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
    textAlign: 'center',
    color: '#FFFFFF'
  }
};
