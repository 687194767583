import React, { Component } from 'react';
import { Table, Row, Input, Col, Button } from "antd";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchSalesHeaders } from "../../redux/actions/salesHeaders";
import { addSalesLine, fetchSalesLines, updateSalesLine, deleteSalesLine } from "../../redux/actions/salesLines";
import { fetchItems, updateAllItems } from "../../redux/actions/items";
import { getItems, getAuthData, getSalesLineMapByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getSalesHeadersByTypeAndNo } from "../../redux/reducers";
import { withRouter } from 'react-router-dom';
import SelectWarehouseLocation from '../common/SelectWarehouseLocation'
import { FaCheck, FaTimes, FaSave, FaUndo } from "react-icons/fa";
import { format } from "currency-formatter";
import uuid from "uuid/v1";
import { locationEnabled, addListener, removeListener } from '../../utils/Location';
import { withToastManager } from 'react-toast-notifications';
import { formatGeoLocation } from "../../utils/Formats";
import SelectCategoryTree, { filterItemsByCategory } from '../common/SelectCategoryTree';
import { getOfflineData, removeOfflineItem, saveOfflineData } from '../../localStorage';
import { decryptValue } from "../common/encryption";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const { Search } = Input;

class ItemTable extends Component {
  items = [];

  state = {
    LocCode: undefined,
    text: undefined,
    salesLine: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false,
    itemFilter: [],
    isSystemOffline: false,
  }

  getColumns = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '20%',
        render: (Description, item) => item.LotNo + ' - ' + Description
      },
      {
        title: 'For Sale',
        dataIndex: 'Inventory',
        key: 'Inventory',
        width: '10%',
        align: 'right',
      },
      {
        title: 'Unit Price',
        dataIndex: 'UnitPrice',
        key: 'UnitPrice',
        width: '10%',
        align: 'right',
      },
      {
        title: 'Qty',
        dataIndex: 'Quntity',
        key: 'Quntity',
        align: 'right',
        width: '15%',
        render: (quntity, item) => this.renderQuntity(item),
      },
      {
        title: 'Discount %',
        dataIndex: 'DisPercentage',
        key: 'DisPercentage',
        align: 'right',
        width: '15%',
        render: (UnitPrice, item) => this.renderLineDiscountPercentage(item)
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        width: '10%',
        render: (UnitPrice, item) => this.renderLineTotal(item)
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        align: 'right',
        width: '5%',
        render: (status, item) => <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>{this.renderStatus(item)}</div>,
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: '15%',
        render: (action, item) => <div style={{ marginRight: 10 }}>
          {this.renderActions(item)}
        </div>
      }
    ];

  getMobileColumns = () =>
    [
      {
        title: <div style={{ width: window.innerWidth - 150 }}>
          <table>
            <tr>
              <td style={{ textAlign: 'right', width: '20%' }}>Price</td>
              <td style={{ textAlign: 'right', width: '30%' }}>Qty</td>
              <td style={{ textAlign: 'right', width: '30%' }}>Dis %</td>
              <td style={{ textAlign: 'right', width: '20%' }}>Total</td>
            </tr>
          </table>
        </div>,
        dataIndex: 'Description',
        key: 'description',
        width: '75%',
        render: (Description, item) => <div style={{ width: window.innerWidth - 150 }}>
          <div style={{ height: 40 }}>{item.LotNo + ' - ' + Description}</div>
          <table>
            <tr>
              <td style={{ textAlign: 'right', width: '20%' }}>{item.UnitPrice}</td>
              <td style={{ textAlign: 'right', width: '30%' }}>{this.renderQuntity(item)}</td>
              <td style={{ textAlign: 'right', width: '30%' }}>{this.renderLineDiscountPercentage(item)}</td>
              <td style={{ textAlign: 'right', width: '20%' }}>{this.renderLineTotal(item)}</td>
            </tr>
          </table>
        </div>
      },
      {
        title: <div style={{ marginRight: 10 }}>For Sale</div>,
        dataIndex: 'Inventory',
        key: 'Inventory',
        align: 'right',
        width: 150,
        render: (Description, item) => <div style={{}}>
          <div style={{ height: 40, marginTop: -7 }}>{item.Inventory}</div>
          <table>
            <tr>
              <td style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>{this.renderStatus(item)}</div>
              </td>
              <td style={{ textAlign: 'right' }}>{this.renderActions(item)}</td>
            </tr>
          </table>
        </div>
      }
    ];

  renderQuntity = item => {
    const { salesLine } = this.state;

    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      var salesLineItem = lineItems[item.LotNo];
    } else {
      var salesLineItem = this.getExistingOrderSalesLine(item);
    }

    const key1 = salesLineItem && (salesLineItem.No + '-' + salesLineItem.LotNo + '-' + salesLineItem.LocCode);
    const key2 = item.ItemNo + '-' + item.LotNo + '-' + item.LocCode;

    let lineQty = '0';

    if (key1 === key2) lineQty = '' + salesLineItem.Quantity;

    if (salesLine) {
      const key3 = salesLine.ItemNo + '-' + salesLine.LotNo + '-' + salesLine.LocCode;

      if (key3 === key2) lineQty = '' + salesLine.Quantity;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={parseFloat(lineQty.replaceAll(',', ''))}
        className="ant-input arrowless"
        min='0'
        style={{ width: 70, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onChange={(e) => this.handleQtyChange(e.target.value, item, salesLineItem)} />
    )
  }

  renderLineTotal = item => {
    const { salesLine } = this.state;
    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      var salesLineItem = lineItems[item.LotNo];
    } else {
      var salesLineItem = this.getExistingOrderSalesLine(item);
    }
    const key1 = salesLineItem && (salesLineItem.No + '-' + salesLineItem.LotNo + '-' + salesLineItem.LocCode);
    const key2 = item.ItemNo + '-' + item.LotNo + '-' + item.LocCode;

    let lineAmount = 0;

    if (key1 === key2) lineAmount = salesLineItem.LineAmount && salesLineItem.LineAmount.replace(/,/g, '');

    if (salesLine) {
      const key3 = salesLine.ItemNo + '-' + salesLine.LotNo + '-' + salesLine.LocCode;

      // if (key3 === key2)  lineAmount = (salesLine.Quantity * item.UnitPrice) - (salesLine.Quantity * item.UnitPrice * salesLine.DisPercentage /100)
      if (key3 === key2) lineAmount = undefined;
    }

    return lineAmount !== undefined ? format(lineAmount, currencyFormat) : 'N/A';
  }

  renderLineDiscount = item => {
    const { salesLine } = this.state;

    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      var salesLineItem = lineItems[item.LotNo];
    } else {
      var salesLineItem = this.getExistingOrderSalesLine(item);
    }

    const key1 = salesLineItem && (salesLineItem.No + '-' + salesLineItem.LotNo + '-' + salesLineItem.LocCode);
    const key2 = item.ItemNo + '-' + item.LotNo + '-' + item.LocCode;

    let discountAmount = 0;

    if (key1 === key2) discountAmount = salesLineItem.DisAmount;

    if (salesLine) {
      const key3 = salesLine.ItemNo + '-' + salesLine.LotNo + '-' + salesLine.LocCode;

      // if (key3 === key2)  discountAmount = salesLine.Quantity * item.UnitPrice * salesLine.DisPercentage /100;
      if (key3 === key2) discountAmount = undefined;
    }
    return discountAmount !== undefined ? format(discountAmount, currencyFormat) : 'N/A';
  }

  renderLineDiscountPercentage = item => {
    const { salesHeaderDetails } = this.props;
    const { salesLine } = this.state;

    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      var salesLineItem = lineItems[item.LotNo];
    } else {
      var salesLineItem = this.getExistingOrderSalesLine(item);
    }

    const baseDiscount = salesHeaderDetails ? salesHeaderDetails.DisPersentage : 0.00;
    const key1 = salesLineItem && (salesLineItem.No + '-' + salesLineItem.LotNo + '-' + salesLineItem.LocCode);
    const key2 = item.ItemNo + '-' + item.LotNo + '-' + item.LocCode;

    let discountPercentage = 0;

    if (key1 === key2) {
      discountPercentage = salesLineItem.DisPercentage;
    }

    if (salesLine) {
      const key3 = salesLine.ItemNo + '-' + salesLine.LotNo + '-' + salesLine.LocCode;

      if (key3 === key2) discountPercentage = salesLine.DisPercentage;
    } else if (!salesLineItem) {
      discountPercentage = baseDiscount;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={discountPercentage}
        className="ant-input arrowless"
        min='0'
        max='100'
        style={{ width: 70, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onKeyDown={e => (e.keyCode === 69 || e.keyCode === 189) && e.preventDefault()}
        onChange={(e) => {
          let { value } = e.target;
          if (value < 0) value *= -1;
          if (value > 100) value = 100;
          this.handleDiscountPercentageChange('' + value, item, salesLineItem)
        }} />
    )
  }

  isDisableRowActions = item => {
    const { salesLine } = this.state;

    if (!salesLine) return false;

    const key1 = item.ItemNo + '-' + item.LotNo + '-' + item.LocCode;
    const key2 = salesLine.ItemNo + '-' + salesLine.LotNo + '-' + salesLine.LocCode;
    return key1 !== key2;
  }

  renderStatus = item => {

    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      var salesLine = lineItems[item.LotNo];
    } else {
      var salesLine = this.getExistingOrderSalesLine(item);
    }

    if (salesLine) {
      if (salesLine.added) {
        return (
          <FaCheck color='#07e607' />
        )
      } else {
        return (
          <FaTimes color='red' />
        )
      }
    } else return null;
  }

  getRowClassName = item => {

    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      if (lineItems) {
        var salesLine = lineItems[item.LotNo];
      }
    } else {
      var salesLine = this.getExistingOrderSalesLine(item);
    }

    if (salesLine) {
      if (salesLine.added) {
        return 'sales-header-selected-item-row'
      } else {
        return ''
      }
    } else return '';
  }

  getExistingOrderSalesLine = item => {
    const { salesLineMap } = this.props;
    const { ItemNo, LotNo, LocCode } = item;
    const key = ItemNo + '-' + LotNo + '-' + LocCode;

    return salesLineMap[key];
  }

  renderActions = (item) => {
    const { salesLine } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disabled={this.isDisableRowActions(item)} type='primary' size={'small'} onClick={this.handleSaveItem} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSave /></Button>
        <Button disabled={this.isDisableRowActions(item)} size={'small'} onClick={this.handleResetItem} style={{ display: 'flex', alignItems: 'center' }}><FaUndo /></Button>
      </div>
    )
  }

  async componentDidMount() {
    const { salesHeader, fetchSalesLines, fetchSalesHeaders } = this.props;
    fetchSalesHeaders({ No: salesHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[salesHeader.DocType] });
    this.setState({ LocCode: salesHeader.LocCode }, this.loadItems);
    this.setDeviceMapLocation();

    if (getOfflineData('state').isSystemOffline) {
      const salesLines = await fetchSalesLines({ DocNo: salesHeader.No, DocType: salesHeader.DocType });

      //Remove existing lineItem key before insert new ones. So storage won't be full.
      for (var key in localStorage) {
        if (key.includes('lineItems_')) {
          localStorage.removeItem(key);
        }
      }
      var lineItemsObj = {};
      salesLines.forEach(function (salesLine) {
        salesLine.OldQuantity = salesLine.Quantity; //Need to use this for temp inventory update.
        lineItemsObj[salesLine.LotNo] = salesLine;
      });
      saveOfflineData('lineItems_' + this.props.salesHeader.No, lineItemsObj);
      this.setState({ isSystemOffline: true });
    }

  }

  loadItems = item => {
    const { authData, fetchItems } = this.props;

    const filter = {
      LocCode: this.state.LocCode,
      UserCode: authData.userId,
      DistributorRange: authData.distributorRange
    }

    if (item) {
      filter.ItemNo = item.ItemNo;
      filter.LotNo = item.LotNo;
    }

    this.setState({ loadingItems: true });
    fetchItems(filter).then(() => {
      this.setState({ loadingItems: false });
    });
  }

  loadSaleHeader = () => {
    const { salesHeader, fetchSalesHeaders } = this.props;

    fetchSalesHeaders({ No: salesHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[salesHeader.DocType] });
  }

  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  handleSaveItem = () => {
    const { salesHeader, addSalesLine, authData, toastManager, updateSalesLine, deleteSalesLine } = this.props;
    const { salesLine, MapLocation } = this.state;

    if (!salesLine) return;

    salesLine.UnitPrice = salesLine.UnitPrice.replace(",", "");

    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      var existingSalesLine = lineItems[salesLine.LotNo];
    } else {
      var existingSalesLine = this.getExistingOrderSalesLine(salesLine);
    }

    if (!locationEnabled) {
      toastManager.add('You can not update sales lines without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    salesLine.Quantity = salesLine.Quantity.toString();

    if (parseInt((salesLine.Quantity).replace(/,/g, '')) > parseInt((salesLine.Inventory).replace(/,/g, ''))) {
      toastManager.add("Item inventory not sufficient.Available quantity " + salesLine.Inventory,
        { autoDismiss: true, appearance: 'error' }
      );
      return false;
    }

    var total = (salesLine.Quantity * salesLine.UnitPrice).toFixed(2);
    const disAmount = (total * salesLine.DisPercentage / 100).toFixed(2);
    const amount = ((total - disAmount).toFixed(2)).toString();

    const salesLineItem = {
      LineSessionID: uuid(),
      DocType: salesHeader.DocType,
      DocNo: salesHeader.No,
      Type: '2',
      No: salesLine.ItemNo,
      MapLocation,
      Quantity: salesLine.Quantity,
      UnitPrice: salesLine.UnitPrice,
      UserCode: authData.userId,
      LotNo: salesLine.LotNo,
      MarketPrice: salesLine.MarketPrice,
      DisPercentage: salesLine.DisPercentage,
      LocCode: salesLine.LocCode,
      LineAmount: amount,
      Amount: amount,
      AmountIncluVAT: amount,
      DisAmount: disAmount.toString(),
      Description: salesLine.Description,
      added: true,
    }

    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData("lineItems_" + salesLineItem.DocNo);
      if (salesLineItem.Quantity == 0) {
        if (existingSalesLine) {
          delete lineItems[salesLineItem.LotNo];
        }
      } else {
        var lineNo = 'line_' + Math.round((new Date()).getTime() / 1000);
        salesLineItem.LineNo = lineNo;
        salesLineItem.OldQuantity = salesLineItem.Quantity;
        lineItems[salesLineItem.LotNo] = salesLineItem;
      }
      saveOfflineData("lineItems_" + salesLineItem.DocNo, lineItems);

      //Update item Inventory only for this view.
      let item = this.items.find(function (el) {
        return el.LotNo == salesLine.LotNo;
      });
      if (salesLine.OldQuantity == null) {
        item.Inventory = (parseInt(item.Inventory.replace(/,/g, '')) - parseInt(salesLine.Quantity.replace(/,/g, ''))).toLocaleString();
      } else if (salesLine.Quantity == 0) {
        item.Inventory = (parseInt(item.Inventory.replace(/,/g, '')) + parseInt(salesLine.OldQuantity.replace(/,/g, ''))).toLocaleString();
      } else if ((salesLine.OldQuantity).localeCompare(salesLine.Quantity)) {
        item.Inventory = (parseInt(item.Inventory.replace(/,/g, ''))
          + parseInt(salesLine.OldQuantity.replace(/,/g, ''))
          - parseInt(salesLine.Quantity.replace(/,/g, ''))).toLocaleString();
      }

      this.getColumns();
      this.setState({ salesLine: undefined });
    } else {
      if (!existingSalesLine) {
        this.handleAction(addSalesLine, salesLineItem); //Add new sales line
      } else {
        const updatedSalesLine = {
          ...salesLineItem,
          LineNo: existingSalesLine.LineNo
        }
        if (salesLineItem.Quantity === '0') {
          this.handleAction(deleteSalesLine, updatedSalesLine); //Remove sales line
        } else {
          this.handleAction(updateSalesLine, updatedSalesLine); //Update sales line
        }
      }
    }
  }

  handleAction = (action, salesLineItem) => {
    const { toastManager } = this.props;

    this.setState({ loading: true });
    action(salesLineItem).then(response => {
      this.setState({ loading: false });
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        this.loadItems({ ...this.state.salesLine });
        this.loadSaleHeader();
        this.setState({ salesLine: undefined });
      }
    })
  }

  handleResetItem = () => this.setState({ salesLine: undefined });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const inEditMode = !!this.state.salesLine;
    const summaryAvailable = !!this.props.salesHeaderDetails;
    const tableFooterHeight = !inEditMode && summaryAvailable ? 135 : 0;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  handleChange = (field, value, error) => {
    this.setState({ [field]: value })
  }

  renderLocationList = () => {
    const { salesHeader = {} } = this.props;

    return (
      <SelectWarehouseLocation
        disabled={true}
        defaultValue={this.state.LocCode}
        onChange={this.handleChange} style={{ width: '100%' }} />
    )
  }

  handleCategoryFilterChange = value => {
    this.setState({ itemFilter: value });
  };

  renderTitle = () => {
    return (
      <Row className="promate-table-header" gutter={5} style={{ paddingBottom: 10 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ marginBottom: 5 }}>
          <SelectCategoryTree itemFilter={this.state.itemFilter} onChange={this.handleCategoryFilterChange} />
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Search
            name='filterText'
            placeholder='Input search text'
            size='default'
            color={'black'}
            onChange={e => this.handleChange('text', e.target.value, '')} />
        </Col>
      </Row>
    )
  }

  renderFooter = () => {
    if (this.state.isSystemOffline) {
      var lineItems = getOfflineData('lineItems_' + this.props.salesHeader.No);
      var includeVatAmount = 0;
      var discountAmount = 0;
      var grossAmount = 0

      if (lineItems) {
        Object.keys(lineItems).forEach(function (key) {
          var lineItem = lineItems[key];
          includeVatAmount += parseFloat(lineItem.AmountIncluVAT);
          discountAmount += parseFloat(lineItem.DisAmount);
          grossAmount += (parseFloat(lineItem.AmountIncluVAT) + parseFloat(lineItem.DisAmount));
        });
      }

      AmountIncluVAT = includeVatAmount.toFixed(2);
      discount = discountAmount.toFixed(2);
      AvgDisPersentage = discount > 0 ? ((discount / grossAmount) * 100).toFixed(2) : null;

    } else {
      var { salesHeaderDetails } = this.props;
      var { AmountIncluVAT, DisAmount, DisPersentage, AvgDisPersentage } = salesHeaderDetails;
      var includeVatAmount = AmountIncluVAT.replace(/,/g, '');
      var discount = DisAmount.replace(/,/g, '');
      var grossAmount = parseFloat(includeVatAmount) + parseFloat(discount);
    }

    const inEditMode = !!this.state.salesLine;
    if (inEditMode) {
      return null;
    } else {
      return (
        <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontSize: "medium" }}>Gross Amount</div>
            <div style={{ fontSize: "medium" }}>{format(grossAmount, currencyFormat)}</div>
          </Col>
          <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontSize: "medium" }}>Discount ({AvgDisPersentage}%) </div>
            <div style={{ fontSize: "medium" }}>{format(discount, currencyFormat)}</div>
          </Col>
          <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontSize: "medium" }}>Net Amount</div>
            <div style={{ fontSize: "medium" }}>{format(AmountIncluVAT, currencyFormat)}</div>
          </Col>
          <Col lg={2} md={2} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <Button block type='primary' onClick={this.handleDoneButton}>Done</Button>
          </Col>
        </Row>
      );
    }
  }

  getFilteredItems = () => {
    const { items = [] } = this.props;
    const { text, itemFilter } = this.state;
    let filteredItems = items;
    filteredItems = items.filter(item => {
      const { Description } = item;
      const descriptionFilter = text ? this.stringCompare(Description, text) : true;

      return descriptionFilter;
    });

    filteredItems = filterItemsByCategory(filteredItems, itemFilter);
    if (decryptValue(localStorage.getItem('userType')) === "r1") {
      filteredItems = filteredItems.filter(filteredItems => filteredItems.DistributorRange === "R01");
    }
    return filteredItems
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  handleQtyChange = (value, item, salesLineItem) => {
    value = value.replaceAll('-', '');
    const { salesLine } = this.state;
    const { salesHeaderDetails } = this.props;
    const baseDiscount = salesHeaderDetails ? salesHeaderDetails.DisPersentage : 0.00;
    this.setState({
      salesLine: {
        ...item,
        DisPercentage: salesLine && salesLine.DisPercentage ? salesLine.DisPercentage : salesLineItem ? salesLineItem.DisPercentage : baseDiscount,
        Quantity: value,
        OldQuantity: salesLineItem ? salesLineItem.Quantity : null
      }
    });
  }

  handleDiscountPercentageChange = (value, item, salesLineItem) => {
    value = value.replaceAll('-', '');
    const { salesLine } = this.state;
    this.setState({
      salesLine: {
        ...item,
        DisPercentage: value,
        Quantity: salesLine && salesLine.Quantity ? salesLine.Quantity : salesLineItem ? salesLineItem.Quantity : 0,
        OldQuantity: salesLineItem ? salesLineItem.OldQuantity : null
      }
    });
  }

  handleDoneButton = async () => {
    const {
      fetchSalesHeaders,
      salesHeader,
      fetchSalesLines,
      addSalesLine,
      deleteSalesLine,
      updateSalesLine,
      updateAllItems,
    } = this.props;

    if (this.state.isSystemOffline) {
      this.setState({ loading: true });

      var lineItems = getOfflineData('lineItems_' + salesHeader.No);
      var salesHeaders = await fetchSalesHeaders({ No: salesHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[salesHeader.DocType] });
      var currentSalesHeader = salesHeaders.data.find(function (el) {
        return el.No == salesHeader.No;
      });

      var salesLines = await fetchSalesLines({ DocNo: currentSalesHeader.No });
      const inventoryItems = this.props.items;
      const oldSalesLinesLotNo = salesLines.map(x => x.LotNo);
      const newSalesLinesLotNo = Object.keys(lineItems);

      var newSalesLinesNo = newSalesLinesLotNo.filter(x => !oldSalesLinesLotNo.includes(x));
      var commonSalesLinesNo = newSalesLinesLotNo.filter(x => oldSalesLinesLotNo.includes(x));
      var deletedSalesLinesNo = oldSalesLinesLotNo.filter(x => !newSalesLinesLotNo.includes(x));

      for (let i = 0; i < newSalesLinesNo.length; i++) {
        await addSalesLine(lineItems[newSalesLinesNo[i]]);
      }

      for (let i = 0; i < deletedSalesLinesNo.length; i++) {
        const itemToDelete = salesLines.find(function (el) {
          return el.LotNo == deletedSalesLinesNo[i];
        });
        await deleteSalesLine(itemToDelete, true);
      }

      for (let i = 0; i < commonSalesLinesNo.length; i++) {
        const oldLineItem = salesLines.find(function (el) {
          return el.LotNo == commonSalesLinesNo[i];
        });
        lineItems[commonSalesLinesNo[i]].LineNo = oldLineItem.LineNo;
        await updateSalesLine(lineItems[commonSalesLinesNo[i]]);
      }

      await updateAllItems(inventoryItems);
      this.setState({ loading: false });
      removeOfflineItem('lineItems_' + salesHeader.No);
    }
    this.props.history.goBack()
  }

  render() {
    const { salesHeaderDetails } = this.props;
    this.items = this.getFilteredItems();
    const { loading, loadingItems } = this.state;

    return (
      <div>
        <Table
          rowKey={'id'}
          rowClassName={(item, index) => this.getRowClassName(item)}
          className='hovering-scroller'
          columns={this.context.screenType === 'MOBILE' ? this.getMobileColumns() : this.getColumns()}
          bodyStyle={{ minHeight: this.getTableHeight(this.items) }}
          dataSource={this.items}
          size={'small'}
          scroll={{ y: this.getScrollAreaHeight() }}
          pagination={false}
          title={this.renderTitle}
          footer={salesHeaderDetails && this.renderFooter}
          loading={loading || (this.items.length === 0 && loadingItems)} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { salesHeader } = ownProps;
  const items = getItems(state);
  // const items = this.getFilteredItems();
  const salesLineMap = getSalesLineMapByDocTypeAndDocNo(state, salesHeader.DocType, salesHeader.No);

  return {
    authData: getAuthData(state),
    items,
    salesHeaderDetails: getSalesHeadersByTypeAndNo(state, salesHeader.DocType, salesHeader.No),
    salesLineMap,
  };
};

ItemTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withToastManager(withRouter(connect(mapStateToProps,
  {
    fetchItems,
    addSalesLine,
    fetchSalesLines,
    updateSalesLine,
    deleteSalesLine,
    fetchSalesHeaders,
    updateAllItems
  }
)(ItemTable)));