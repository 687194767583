import { decryptValue } from "../components/common/encryption";
export default class Api { }
const crypto = require('crypto');
const { hostname } = window.location;
const host = hostname === 'localhost' ? 'localhost:9000' : hostname.startsWith('192.168.') ? hostname + ':9000' : window.location.host;
const localAPIhost = hostname === 'localhost' ? 'localhost:5000' : hostname.startsWith('192.168.') ? hostname + ':5000' : window.location.host + "/sales-api";
Api.server = window.location.protocol + '//' + host + '/api';
Api.salesApi = window.location.protocol + '//' + localAPIhost;


Api.endpointAuth = Api.server;
Api.endpointUsers = Api.server;
Api.endpointInventory = Api.server;
Api.endpointOrders = Api.server;
Api.endpointCustomers = Api.server;
Api.endpointInvoices = Api.server;
Api.endpointCollections = Api.server;
Api.endpointFiles = Api.server;
Api.endpointHealth = Api.server;
Api.endpointRoutes = Api.server;
Api.endpointGeneric = Api.server;
Api.endpointReports = Api.server;

// function changeUrl(type) {
//   Api.endpointUsers = Api.server +'/'+type+ '/users';
//   Api.endpointInventory = Api.server +'/'+type+ '/inventoryItems';
//   Api.endpointOrders = Api.server +'/'+type+ '/orders';
//   Api.endpointCustomers = Api.server +'/'+type+ '/customers';
//   Api.endpointInvoices = Api.server +'/'+type+ '/invoices';
//   Api.endpointCollections = Api.server +'/'+type+ '/collections';
//   Api.endpointFiles = Api.server +'/'+type+ '/files';
//   Api.endpointHealth = Api.server +'/'+type+ '/health';
//   Api.endpointRoutes = Api.server + '/'+type+ '/routes';
//   Api.endpointGeneric = Api.server + '/'+type+ '/generic';
//   Api.endpointReports = Api.server + '/'+type+ '/reports';
// }

// SALES API
Api.local = {
  order: {
    headers: {
      url: () => (Api.salesApi + '/order/headers'),
      method: 'GET',
    },
    lines: {
      url: () => (Api.salesApi + '/order/lines'),
      method: 'GET',
    },
    failedHeaders: {
      url: (filter) => (Api.salesApi + `/order/failed/headers?from=${filter.from}&to=${filter.to}`),
      method: 'GET',
    },
    failedLines: {
      url: (headerId) => (Api.salesApi + `/order/failed/headers/${encodeURIComponent(headerId)}`),
      method: 'GET',
    }
  }
};

// TARGET SCHEDULER
Api.targetScheduler = {
  years: {
    url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/years'),
    method: 'GET'
  },
  months: {
    url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/months'),
    method: 'GET'
  },
  brands: {
    url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/brands'),
    method: 'GET'
  },
  itemSubCategoryOne: {
    url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/item/sub-category/one'),
    method: 'GET'
  },
  monthlyTarget: {
    url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/monthlyTarget'),
    method: 'POST'
  },
  routeTarget: {
    getAll: {
      url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/routeTarget/all'),
      method: 'POST'
    },
    update: {
      url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/routeTarget/update'),
      method: 'POST'
    }
  },
  customerFrequency: {
    getAll: {
      url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/customerFrequency/all'),
      method: "POST"
    },
    update: {
      url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/customerFrequency/update'),
      method: "POST"
    }
  },
  customerVisitTarget: {
    getAll: {
      url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/customerVisitTarget/all'),
      method: 'POST'
    },
    update: {
      url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/customerVisitTarget/update'),
      method: 'POST'
    }
  },
  targetScheduler: {
    getAll: {
      url: () => (Api.server + '/'+decryptValue(localStorage.getItem('userType'))+ '/targetScheduler/all'),
      method: 'POST'
    }
  }
}

Api.auth = {
  url: () => Api.endpointAuth+ '/'+decryptValue(localStorage.getItem('userType'))+'/auth',
  // url: () => Api.endpointAuth+'/auth',
  method: 'POST',
  resetPassword: {
    url: () => Api.endpointAuth + '/'+decryptValue(localStorage.getItem('userType'))+'/auth'+ '/resetPassword',
    method: 'POST',
  },
  sendPasswordResetOTP: {
    url: () => Api.endpointAuth + '/'+decryptValue(localStorage.getItem('userType'))+'/auth'+ '/sendPasswordResetOTP',
    method: 'POST',
  }
};

Api.users = {
  getAll: {
    url: () => Api.endpointUsers+'/'+decryptValue(localStorage.getItem('userType'))+'/users',
    method: 'GET',
  },
  update: {
    url: userId => Api.endpointUsers +'/'+decryptValue(localStorage.getItem('userType'))+'/users'+ '/' + encodeURIComponent(userId),
    method: 'PUT',
  },
  get: {
    url: userId => Api.endpointUsers +'/'+decryptValue(localStorage.getItem('userType'))+'/users'+ '/' + encodeURIComponent(userId),
    method: 'GET',
  },
};

Api.inventory = {
  getAll: {
    url: () => Api.endpointInventory+'/'+decryptValue(localStorage.getItem('userType'))+'/inventoryItems',
    method: 'GET',
  },
  get: {
    url: id => Api.endpointInventory +'/'+decryptValue(localStorage.getItem('userType'))+'/inventoryItems' + '/' + id,
    method: 'GET',
  },
};

Api.orders = {
  getAll: {
    url: () => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders',
    method: 'GET',
  },
  getForDateRange: {
    url: () => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ '/find',
    method: 'POST',
  },
  get: {
    url: orderId => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ "/" + orderId,
    method: 'GET',
  },
  add: {
    url: () => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders',
    method: 'POST',
  },
  update: {
    url: id => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ "/" + id,
    method: 'PATCH',
  },
  confirm: {
    url: id => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ '/' + id + '/confirm',
    method: 'POST',
  },
  delete: {
    url: id => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ '/' + id,
    method: 'DELETE',
  },
  salesLines: {
    add: {
      url: orderId => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ '/' + orderId + '/salesLines',
      method: 'POST',
    },
    delete: {
      url: (orderId, lineNumber) => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ '/' + orderId + '/salesLines/' + lineNumber,
      method: 'DELETE',
    },
    quantity: {
      update: {
        url: (orderId, lineNumber) => Api.endpointOrders +'/'+decryptValue(localStorage.getItem('userType'))+'/orders'+ '/' + orderId + '/salesLines/' + lineNumber + '/quantity',
        method: 'PUT',
      },
    },
  },
};

Api.customers = {
  getAll: {
    url: () => Api.endpointCustomers +'/'+decryptValue(localStorage.getItem('userType'))+'/customers',
    method: 'GET',
  },
  get: {
    url: customerId => Api.endpointCustomers +'/'+decryptValue(localStorage.getItem('userType'))+'/customers'+ "/" + customerId,
    method: 'GET',
  },
  orders: {
    getAll: {
      url: customerId => Api.endpointCustomers +'/'+decryptValue(localStorage.getItem('userType'))+'/customers'+ "/" + customerId + "/orders",
      method: 'GET',
    }
  },
  add: {
    url: () => Api.endpointCustomers +'/'+decryptValue(localStorage.getItem('userType'))+'/customers',
    method: 'POST',
  },
  categories: {
    getAll: {
      url: () => Api.endpointCustomers +'/'+decryptValue(localStorage.getItem('userType'))+'/customers'+ "/categories",
      method: 'GET',
    }
  },
  update: {
    url: () => Api.endpointCustomers +'/'+decryptValue(localStorage.getItem('userType'))+'/customers',
    method: 'PUT',
  },
};

Api.invoices = {
  getAll: {
    url: () => Api.endpointInvoices +'/'+decryptValue(localStorage.getItem('userType'))+'/invoices'+ '/filter',
    method: 'POST',
  },
  get: {
    url: invoiceNo => Api.endpointInvoices +'/'+decryptValue(localStorage.getItem('userType'))+'/invoices'+ "/" + invoiceNo,
    method: 'GET',
  },
};

Api.collections = {
  getAll: {
    url: () => Api.endpointCollections +'/'+decryptValue(localStorage.getItem('userType'))+'/collections'+ '/filter',
    method: 'POST',
  },
  get: {
    url: collectionId => Api.endpointCollections +'/'+decryptValue(localStorage.getItem('userType'))+'/collections'+ "/" + collectionId,
    method: 'GET',
  },
  add: {
    url: () => Api.endpointCollections +'/'+decryptValue(localStorage.getItem('userType'))+'/collections',
    method: 'POST',
  },
  update: {
    url: id => Api.endpointCollections +'/'+decryptValue(localStorage.getItem('userType'))+'/collections'+ "/" + id,
    method: 'PATCH',
  },
  delete: {
    url: id => Api.endpointCollections +'/'+decryptValue(localStorage.getItem('userType'))+'/collections'+ '/' + id,
    method: 'DELETE',
  },
  addAttachments: {
    url: id => Api.endpointCollections +'/'+decryptValue(localStorage.getItem('userType'))+'/collections'+ '/' + id + '/links',
    method: 'PATCH',
  },
  confirm: {
    url: id => Api.endpointCollections +'/'+decryptValue(localStorage.getItem('userType'))+'/collections'+ '/' + id + '/confirm',
    method: 'POST',
  }
};
Api.files = {
  upload: {
    url: () => Api.endpointFiles +'/'+decryptValue(localStorage.getItem('userType'))+'/files',
    method: 'POST',
  },
  download: {
    url: path => Api.endpointFiles +'/'+decryptValue(localStorage.getItem('userType'))+'/files'+ '/' + path,
    method: 'GET',
  },
};
Api.networkDetector = {
  health: {
    url: () => Api.endpointHealth +'/'+decryptValue(localStorage.getItem('userType'))+'/health',
    method: 'GET',
  }
};

Api.routes = {
  getAll: {
    url: () => Api.endpointRoutes +'/'+decryptValue(localStorage.getItem('userType'))+'/routes',
    method: 'GET',
  }
};

Api.generic = {
  health: {
    url: () => Api.endpointGeneric +'/'+decryptValue(localStorage.getItem('userType'))+'/generic'+ '/health',
    method: 'GET',
  },
  get: {
    url: objectName => Api.endpointGeneric +'/'+decryptValue(localStorage.getItem('userType'))+'/generic'+ '/' + objectName + '/filter',
    method: 'POST',
  },
  update: {
    url: objectName => Api.endpointGeneric +'/'+decryptValue(localStorage.getItem('userType'))+'/generic'+ '/' + objectName,
    method: 'PUT',
  },
  add: {
    url: objectName => Api.endpointGeneric +'/'+decryptValue(localStorage.getItem('userType'))+'/generic'+ '/' + objectName,
    method: 'POST',
  },
  delete: {
    url: objectName => Api.endpointGeneric +'/'+decryptValue(localStorage.getItem('userType'))+'/generic'+ '/' + objectName,
    method: 'DELETE',
  },
};

Api.reports = {
  generic: {
    create: {
      url: type => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/' + type,
      method: 'POST'
    }
  },
  sales: {
    create: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/sales',
      method: 'POST'
    }
  },
  stockTransfer: {
    create: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/stockTransfer',
      method: 'POST'
    }
  },
  stockTake: {
    create: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/stockTake',
      method: 'POST'
    }
  },

  receipt: {
    create: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/receipt',
      method: 'POST'
    }
  },
  purchase: {
    create: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/purchase',
      method: 'POST'
    }
  },
  expense: {
    create: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/expense',
      method: 'POST'
    }
  },
  routeWiseDaily: {
    calculate: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/calculate/routeWiseDailyReport ',
      method: 'POST'
    },
    generate: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/generate/routeWiseDailyReport ',
      method: 'POST'
    }
  },
  routeAchievementReport: {
    calculate: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/calculate/routeAchievementReport',
      method: 'POST'
    },
    generate: {
      url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/generate/routeAchievementReport',
      method: 'POST'
    }
  },
  customerWiseVisitReport: {
    url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/generate/customerWiseVisitReport',
    method: 'POST'
  },
  routeWiseProductiveReport: {
    url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/generate/routeWiseProductiveReport',
    method: 'POST'
  },
  customerWiseDiscountReport: {
    url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/generate/customerWiseDiscountReport',
    method: 'POST'
  },
  dailyCategoryReport: {
    url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports' + '/generate/dailyCategoryReport',
    method: 'POST'
  },
  stockCountReport: {
    url: () => Api.endpointReports +'/'+decryptValue(localStorage.getItem('userType'))+'/reports'+ '/generate/stockCountReport',
    method: 'POST'
  }
};